import { ethers } from "ethers";

export const RISE_ADDRESS = [
  "Rise Works Inc.",
  "30799 Pinetree Rd. #211",
  "Pepper Pike, OH 44124",
];

export const SESSION_EXPIRATION = 86400 * 1000 * 7; // 1 week

export const BIRTHDATE_YEARS = 12;

export const APP_NAME = "Rise Pay";

export const ROLES = {
  CLIENT: "client",
  CONTRACTOR: "contractor",
};
export const ALLOWANCE_LIMIT = ethers.BigNumber.from(
  "0xffffffffffffffffffffffff"
); /** for ERC20s that cap approve at 96 bits  */

export const explorerUrlsByChainID = {
  1: "https://etherscan.io/",
  10: "https://optimistic.etherscan.io/",
  42161: "https://arbiscan.io/",
  137: "https://polygonscan.com/",
  56: "https://bscscan.com/",
  43114: "https://snowtrace.io/",
  421613: "https://goerli.arbiscan.io/",
  421614: "https://sepolia.arbiscan.io/",
};

export const PROVIDER_LOGOS = {
  brave: "/icons/brave.svg",
  metamask: "/icons/metamask.svg",
  "coinbase wallet": "/icons/coinbase.png",
  coinbase: "/icons/coinbase.png",
  mew: "/icons/mew.png",
  "mew wallet": "/icons/mew.png",
  torus: "/icons/torus.svg",
  rise: "/logo/rise-symbol.png",
  "security key": "/logo/rise-symbol.svg",
  "rise security key": "/logo/rise-symbol.svg",
  other: "/icons/other.svg",
};

export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";

export const UnblockSupportedCountries = [
  "Albania",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Colombia",
  "Comoros",
  "Congo, Republic of the",
  "Costa Rica",
  "Croatia",
  "Cyprus",
  "Czechia",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Eswatini",
  "Ethopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guyana",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Kenya",
  "Kiribati",
  "South Korea",
  "Kuwait",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Palau",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "South Africa",
  "Spain",
  "Sri Lanka",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tuvalu",
  "United Arab Emirates",
  "United Kingdom",
  "Uruguay",
  "Vatican City",
  "Venezuela",
  "Western Sahara",
  "Zambia",
];

export const routeFusionHints = {
  CA: {
    transitCode:
      "Format: AAA-BBBBB or BBBBB-AAA, where 'A' is the Institutional Code and 'B' is the Transit Code",
  },
};

export const routeFusionLabels = {
  IN: {
    routingCode: "IFSC Code",
  },
};
