import { default as accountcIM8FW5kcJMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/account.vue?macro=true";
import { default as index7HPwu18aiqMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/index.vue?macro=true";
import { default as blockchain_45walletgYN19UOv1cMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/blockchain-wallet.vue?macro=true";
import { default as dedicated_45addressE51nzOYZt0Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/dedicated-address.vue?macro=true";
import { default as optionsMEDIbNaaXPMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options.vue?macro=true";
import { default as indexeP9LIfdSrLMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/index.vue?macro=true";
import { default as binanceOeUy8Tv7zRMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/binance.vue?macro=true";
import { default as _91network_93Hi3IXTuIH7Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/[network].vue?macro=true";
import { default as indexwvQrCacZpyMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/index.vue?macro=true";
import { default as brlagdIs0icq9jMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/brla.vue?macro=true";
import { default as coinbase_walletqimqQJspg4Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase_wallet.vue?macro=true";
import { default as coinbasexP8O2KZgXmMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase.vue?macro=true";
import { default as eurpOzz9wVV6NMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/eur.vue?macro=true";
import { default as forexlSW4ElgrSqMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/forex.vue?macro=true";
import { default as gbpbyjqDwyjMHMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/gbp.vue?macro=true";
import { default as international_bankkcdB1ulA3EMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international_bank.vue?macro=true";
import { default as internationalGQc1T1ENxgMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international.vue?macro=true";
import { default as krakenTmcWoa5SS8Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/kraken.vue?macro=true";
import { default as ngnU7tICyI2IAMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/ngn.vue?macro=true";
import { default as us6zpE1FvHMTMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/us.vue?macro=true";
import { default as optionsQgHEwK7AyGMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options.vue?macro=true";
import { default as rampsHELGVtink8Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/ramps.vue?macro=true";
import { default as coinbaseuy5o1WkB2PMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/coinbase.vue?macro=true";
import { default as verify_45walletR8XlSkzbyXMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/verify-wallet.vue?macro=true";
import { default as _91uuid_93WaHgkmW54TMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/[uuid].vue?macro=true";
import { default as _91token_93W0lJIo5ZhVMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/b2b/[token].vue?macro=true";
import { default as loginaeYvMpFVDyMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/login.vue?macro=true";
import { default as registerIQrg3pFD9EMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/register.vue?macro=true";
import { default as blockedz9X7jrMBJgMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/blocked.vue?macro=true";
import { default as classificationCzlyT5JFokMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/classification.vue?macro=true";
import { default as indexj4wy7fRvQjMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/index.vue?macro=true";
import { default as index4cyVQWj9WYMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/index.vue?macro=true";
import { default as dashboard3kkozLRK4bMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/dashboard.vue?macro=true";
import { default as developerGaVwZOthY5Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/developer.vue?macro=true";
import { default as pay_scheduleUFKMDhZTDrMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/[payschedule]/pay_schedule.vue?macro=true";
import { default as indexyrsJSm2DZeMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/index.vue?macro=true";
import { default as _91accountId_93mumbA4bIUKMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/dedicated-address/[accountId].vue?macro=true";
import { default as deposit_45uso7QNRw8nv7Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit-us.vue?macro=true";
import { default as depositBokRiAn3AwMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit.vue?macro=true";
import { default as _91accountId_931uxBUpnEFGMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/external-account/[accountId].vue?macro=true";
import { default as riseideLJZbD9vupMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/riseid.vue?macro=true";
import { default as fund1ruEGledOOMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund.vue?macro=true";
import { default as indexz1xYbcm3K8Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/index.vue?macro=true";
import { default as _91code_9359OykH5ISiMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invite/[code].vue?macro=true";
import { default as _91id_93w3mg3Tx0MNMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/[id].vue?macro=true";
import { default as indexCszjEeUhoMMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/index.vue?macro=true";
import { default as indexflgoDleOwkMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/index.vue?macro=true";
import { default as jwtTOTJRMRqQJMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/jwt.vue?macro=true";
import { default as indexZtY7fKWMv0Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/index.vue?macro=true";
import { default as teamxHujb4s7DrMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/team.vue?macro=true";
import { default as indexMNLtxE3RBUMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/index.vue?macro=true";
import { default as onboardingrvpzwk9IU4Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/onboarding.vue?macro=true";
import { default as hourlyYc7YmHQKTfMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/hourly.vue?macro=true";
import { default as instant8kAP7ngG17Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/instant.vue?macro=true";
import { default as milestoneSS6RyX2TWxMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/milestone.vue?macro=true";
import { default as onceDNE9ajDz23Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/once.vue?macro=true";
import { default as recurringV6rSFqzg1TMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/recurring.vue?macro=true";
import { default as _91userId_93U3ZuRV8vPdMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId].vue?macro=true";
import { default as csvPcMkzwuFZLMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/csv.vue?macro=true";
import { default as indexVvqwS3E2m4Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/index.vue?macro=true";
import { default as indexOCPmTfDdx5Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/index.vue?macro=true";
import { default as managementCf9WQBaCGNMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/management.vue?macro=true";
import { default as _91id_93iv71OSYSiKMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/fund/[id].vue?macro=true";
import { default as _91id_93ZD1dlSfm8IMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/payment/[id].vue?macro=true";
import { default as _91psId_93SqCSfWNtEPMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/[psId].vue?macro=true";
import { default as indexuB7gPF5uT7Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/index.vue?macro=true";
import { default as transaction_45history99qHh9jZEXMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/transaction-history.vue?macro=true";
import { default as playground44AvaU6fdvMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/playground.vue?macro=true";
import { default as business64XbCfbL1TMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/business.vue?macro=true";
import { default as indexR4J6H5IDPrMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/index.vue?macro=true";
import { default as referralB7dO8yjmBuMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/referral.vue?macro=true";
import { default as riseid_45erc20_45withdrawRg8JjGkliGMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid-erc20-withdraw.vue?macro=true";
import { default as riseidQfIJEWQ5GkMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid.vue?macro=true";
import { default as securityI7GcCbagZ5Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/security.vue?macro=true";
import { default as sign_45document1iPJRzeJC8Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-document.vue?macro=true";
import { default as _2fadNDzAe7PNyMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/2fa.vue?macro=true";
import { default as callbackKAQLtCPEVhMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/callback.vue?macro=true";
import { default as codekGhCJhS8iYMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/code.vue?macro=true";
import { default as indexQpFIYeIOoCMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/index.vue?macro=true";
import { default as sign_45outKOb8xNIdQtMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-out.vue?macro=true";
import { default as codeAtSk8PzEgCMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/code.vue?macro=true";
import { default as indexX5hSEyv5zrMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/index.vue?macro=true";
import { default as waiting3tUQD1fTaYMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/waiting.vue?macro=true";
import { default as indexvuCUTSnTnEMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/index.vue?macro=true";
import { default as optionsyjaoc330D8Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/options.vue?macro=true";
import { default as index7rEcSGhvRkMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/[id]/index.vue?macro=true";
import { default as importaHssSVGQB9Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/import.vue?macro=true";
import { default as indexMIVupUNb5FMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/index.vue?macro=true";
import { default as batch_warmed_light9WAy9rIeKYMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed_light.vue?macro=true";
import { default as batch_warmedPIKnAPFa9WMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed.vue?macro=true";
import { default as direct5Zosoj9ncXMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/direct.vue?macro=true";
import { default as sumsubi6SVoM2Ne4Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/sumsub.vue?macro=true";
import { default as warmed_lightyb2qqCAPiLMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed_light.vue?macro=true";
import { default as warmed0UtzNdpKipMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed.vue?macro=true";
import { default as invitemX2rPYCTO8Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite.vue?macro=true";
import { default as indexC8gCXqqlaHMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/tasks/index.vue?macro=true";
import { default as _91accountId_93XzVnn8oadmMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/blockchain/[accountId].vue?macro=true";
import { default as _91accountId_93JrhFveJZfuMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/wire/[accountId].vue?macro=true";
import { default as withdrawgcAd5HAcjWMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: accountcIM8FW5kcJMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/account.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-funding",
    path: "/accounts/add-funding",
    meta: index7HPwu18aiqMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-funding-options",
    path: "/accounts/add-funding/options",
    meta: optionsMEDIbNaaXPMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options.vue").then(m => m.default || m),
    children: [
  {
    name: "accounts-add-funding-options-blockchain-wallet",
    path: "blockchain-wallet",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/blockchain-wallet.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-funding-options-dedicated-address",
    path: "dedicated-address",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/dedicated-address.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "accounts-add-withdraw",
    path: "/accounts/add-withdraw",
    meta: indexeP9LIfdSrLMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options",
    path: "/accounts/add-withdraw/options",
    meta: optionsQgHEwK7AyGMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options.vue").then(m => m.default || m),
    children: [
  {
    name: "accounts-add-withdraw-options-binance",
    path: "binance",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/binance.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-blockchain-wallet-network",
    path: "blockchain-wallet/:network()",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/[network].vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-blockchain-wallet",
    path: "blockchain-wallet",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-brla",
    path: "brla",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/brla.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-coinbase_wallet",
    path: "coinbase_wallet",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase_wallet.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-coinbase",
    path: "coinbase",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-eur",
    path: "eur",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/eur.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-forex",
    path: "forex",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/forex.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-gbp",
    path: "gbp",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/gbp.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-international_bank",
    path: "international_bank",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international_bank.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-international",
    path: "international",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-kraken",
    path: "kraken",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/kraken.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-ngn",
    path: "ngn",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/ngn.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-us",
    path: "us",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/us.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "accounts-add-withdraw-ramps",
    path: "/accounts/add-withdraw/ramps",
    meta: rampsHELGVtink8Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/ramps.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-callback-coinbase",
    path: "/accounts/add/callback/coinbase",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-callback-verify-wallet",
    path: "/accounts/add/callback/verify-wallet",
    meta: verify_45walletR8XlSkzbyXMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/verify-wallet.vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-uuid",
    path: "/auth/invite/:uuid()",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-b2b-token",
    path: "/auth/invite/b2b/:token()",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/b2b/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "blocked",
    path: "/blocked",
    meta: blockedz9X7jrMBJgMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/blocked.vue").then(m => m.default || m)
  },
  {
    name: "clients-id-classification",
    path: "/clients/:id()/classification",
    meta: classificationCzlyT5JFokMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/classification.vue").then(m => m.default || m)
  },
  {
    name: "clients-id",
    path: "/clients/:id()",
    meta: indexj4wy7fRvQjMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "clients",
    path: "/clients",
    meta: index4cyVQWj9WYMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboard3kkozLRK4bMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "developer",
    path: "/developer",
    meta: developerGaVwZOthY5Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/developer.vue").then(m => m.default || m)
  },
  {
    name: "documents-payschedule-pay_schedule",
    path: "/documents/:payschedule()/pay_schedule",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/[payschedule]/pay_schedule.vue").then(m => m.default || m)
  },
  {
    name: "documents",
    path: "/documents",
    meta: indexyrsJSm2DZeMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "fund",
    path: "/fund",
    meta: fund1ruEGledOOMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-dedicated-address-accountId",
    path: "dedicated-address/:accountId()",
    meta: _91accountId_93mumbA4bIUKMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/dedicated-address/[accountId].vue").then(m => m.default || m)
  },
  {
    name: "fund-deposit-us",
    path: "deposit-us",
    meta: deposit_45uso7QNRw8nv7Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit-us.vue").then(m => m.default || m)
  },
  {
    name: "fund-deposit",
    path: "deposit",
    meta: depositBokRiAn3AwMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit.vue").then(m => m.default || m)
  },
  {
    name: "fund-external-account-accountId",
    path: "external-account/:accountId()",
    meta: _91accountId_931uxBUpnEFGMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/external-account/[accountId].vue").then(m => m.default || m)
  },
  {
    name: "fund-riseid",
    path: "riseid",
    meta: riseideLJZbD9vupMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/riseid.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexz1xYbcm3K8Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invite-code",
    path: "/invite/:code()",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invite/[code].vue").then(m => m.default || m)
  },
  {
    name: "invoices-add-id",
    path: "/invoices/add/:id()",
    meta: _91id_93w3mg3Tx0MNMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/[id].vue").then(m => m.default || m)
  },
  {
    name: "invoices-add",
    path: "/invoices/add",
    meta: indexCszjEeUhoMMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices",
    path: "/invoices",
    meta: indexflgoDleOwkMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "jwt",
    path: "/jwt",
    meta: jwtTOTJRMRqQJMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/jwt.vue").then(m => m.default || m)
  },
  {
    name: "management-company-create",
    path: "/management/company/create",
    meta: indexZtY7fKWMv0Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/index.vue").then(m => m.default || m)
  },
  {
    name: "management-company-create-team",
    path: "/management/company/create/team",
    meta: teamxHujb4s7DrMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/team.vue").then(m => m.default || m)
  },
  {
    name: "management-company",
    path: "/management/company",
    meta: indexMNLtxE3RBUMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingrvpzwk9IU4Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId",
    path: "/payments/:userId()",
    meta: _91userId_93U3ZuRV8vPdMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: "payments-userId-hourly",
    path: "hourly",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/hourly.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-instant",
    path: "instant",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/instant.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-milestone",
    path: "milestone",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/milestone.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-once",
    path: "once",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/once.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-recurring",
    path: "recurring",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/recurring.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "payments-batch-csv",
    path: "/payments/batch/csv",
    meta: csvPcMkzwuFZLMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/csv.vue").then(m => m.default || m)
  },
  {
    name: "payments-batch",
    path: "/payments/batch",
    meta: indexVvqwS3E2m4Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/index.vue").then(m => m.default || m)
  },
  {
    name: "payments",
    path: "/payments",
    meta: indexOCPmTfDdx5Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "payments-management",
    path: "/payments/management",
    meta: managementCf9WQBaCGNMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/management.vue").then(m => m.default || m)
  },
  {
    name: "payments-receipts-fund-id",
    path: "/payments/receipts/fund/:id()",
    meta: _91id_93iv71OSYSiKMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/fund/[id].vue").then(m => m.default || m)
  },
  {
    name: "payments-receipts-payment-id",
    path: "/payments/receipts/payment/:id()",
    meta: _91id_93ZD1dlSfm8IMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: "payments-timesheets-psId",
    path: "/payments/timesheets/:psId()",
    meta: _91psId_93SqCSfWNtEPMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/[psId].vue").then(m => m.default || m)
  },
  {
    name: "payments-timesheets",
    path: "/payments/timesheets",
    meta: indexuB7gPF5uT7Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/index.vue").then(m => m.default || m)
  },
  {
    name: "payments-transaction-history",
    path: "/payments/transaction-history",
    meta: transaction_45history99qHh9jZEXMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/transaction-history.vue").then(m => m.default || m)
  },
  {
    name: "playground",
    path: "/playground",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: "profile-business",
    path: "/profile/business",
    meta: business64XbCfbL1TMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/business.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexR4J6H5IDPrMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "referral",
    path: "/referral",
    meta: referralB7dO8yjmBuMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/referral.vue").then(m => m.default || m)
  },
  {
    name: "riseid-erc20-withdraw",
    path: "/riseid-erc20-withdraw",
    meta: riseid_45erc20_45withdrawRg8JjGkliGMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid-erc20-withdraw.vue").then(m => m.default || m)
  },
  {
    name: "riseid",
    path: "/riseid",
    meta: riseidQfIJEWQ5GkMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid.vue").then(m => m.default || m)
  },
  {
    name: "security",
    path: "/security",
    meta: securityI7GcCbagZ5Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/security.vue").then(m => m.default || m)
  },
  {
    name: "sign-document",
    path: "/sign-document",
    meta: sign_45document1iPJRzeJC8Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-document.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-2fa",
    path: "/sign-in/2fa",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/2fa.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-callback",
    path: "/sign-in/callback",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/callback.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-code",
    path: "/sign-in/code",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-in",
    path: "/sign-in",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-out",
    path: "/sign-out",
    meta: sign_45outKOb8xNIdQtMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-out.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-code",
    path: "/sign-up/code",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-waiting",
    path: "/sign-up/waiting",
    meta: waiting3tUQD1fTaYMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/waiting.vue").then(m => m.default || m)
  },
  {
    name: "subscription",
    path: "/subscription",
    meta: indexvuCUTSnTnEMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/index.vue").then(m => m.default || m)
  },
  {
    name: "subscription-options",
    path: "/subscription/options",
    meta: optionsyjaoc330D8Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/options.vue").then(m => m.default || m)
  },
  {
    name: "talent-id",
    path: "/talent/:id()",
    meta: index7rEcSGhvRkMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "talent-import",
    path: "/talent/import",
    meta: importaHssSVGQB9Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/import.vue").then(m => m.default || m)
  },
  {
    name: "talent",
    path: "/talent",
    meta: indexMIVupUNb5FMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/index.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite",
    path: "/talent/invite",
    meta: invitemX2rPYCTO8Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite.vue").then(m => m.default || m),
    children: [
  {
    name: "talent-invite-batch_warmed_light",
    path: "batch_warmed_light",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed_light.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-batch_warmed",
    path: "batch_warmed",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-direct",
    path: "direct",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/direct.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-sumsub",
    path: "sumsub",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/sumsub.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-warmed_light",
    path: "warmed_light",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed_light.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-warmed",
    path: "warmed",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tasks",
    path: "/tasks",
    meta: indexC8gCXqqlaHMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "withdraw",
    path: "/withdraw",
    meta: withdrawgcAd5HAcjWMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw.vue").then(m => m.default || m),
    children: [
  {
    name: "withdraw-blockchain-accountId",
    path: "blockchain/:accountId()",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/blockchain/[accountId].vue").then(m => m.default || m)
  },
  {
    name: "withdraw-wire-accountId",
    path: "wire/:accountId()",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/wire/[accountId].vue").then(m => m.default || m)
  }
]
  }
]