import { Calendar, DatePicker, setupCalendar } from "v-calendar";
import "v-calendar/style.css";

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp.vueApp;
  // Use plugin defaults (optional)
  app.use(setupCalendar, {});

  // Use the components
  app.component("VCalendar", Calendar);
  app.component("VcDatePicker", DatePicker);
});
